import { FC } from 'react'
import { uniq } from 'lodash'
import { isIncludeFields, sortByAlphabet } from 'utils/array'
import { Contact } from '@types'
import { Row } from 'react-table'
import { ContactsGlobalFilter } from '../types'
import Filter, { FilterOption } from 'components/organisms/AdvancedFilter'
import Accordion from 'components/molecules/Accordion'
import { parseInfoField } from 'utils/parseInfoField'

export const AdvancedFilters: FC<{
  filteredRows: Contact[]
  globalFilter: ContactsGlobalFilter
  setGlobalFilter: (filterValue: any) => void
}> = ({ filteredRows, globalFilter, setGlobalFilter }) => {
  const contactsCabinets = filteredRows
    .reduce<string[]>((acc, { info }) => {
      const { cabinet } = parseInfoField(info)
      return [...acc, cabinet]
    }, [])
    .sort((a, b) => sortByAlphabet(a?.toString(), b?.toString()))
  const uniqCabinets = uniq(contactsCabinets)

  const contactsRoles = filteredRows
    .reduce<string[]>((acc, { info }) => {
      const { role } = parseInfoField(info)
      return [...acc, role]
    }, [])
    .sort((a, b) => sortByAlphabet(a?.toString(), b?.toString()))
  const uniqRoles = uniq(contactsRoles)

  const contactVisibility = filteredRows.reduce<string[]>((acc, { visible }) => {
    const visibility = visible ? 'Visible' : 'Hidden'
    return [...acc, visibility]
  }, [])

  const uniqVisibility = uniq(contactVisibility)

  const handleChangeAdvancedFilter = ({
    cabinet,
    role,
    visibility,
  }: {
    cabinet?: string | null
    role?: string | null
    visibility?: string | null
  }) => {
    setGlobalFilter({
      ...globalFilter,
      cabinet: cabinet ? (cabinet === globalFilter.cabinet ? '' : cabinet) : globalFilter.cabinet,
      role: role ? (role === globalFilter.role ? '' : role) : globalFilter.role,
      visibility: visibility ? (visibility === globalFilter.visibility ? '' : visibility) : globalFilter.visibility,
    })
  }

  return (
    <Filter>
      <Accordion
        title="Cabinet"
        items={uniqCabinets.map((cabinet) => (
          <FilterOption
            key={cabinet}
            filterName={cabinet}
            count={contactsCabinets.filter((c) => c === cabinet).length}
            isSelected={globalFilter.cabinet === cabinet}
            onClick={() => handleChangeAdvancedFilter({ cabinet })}
          />
        ))}
      />
      <Accordion
        title="Role"
        items={uniqRoles.map((role) => (
          <FilterOption
            key={role}
            filterName={role}
            count={contactsRoles.filter((r) => r === role).length}
            isSelected={globalFilter.role === role}
            onClick={() => handleChangeAdvancedFilter({ role })}
          />
        ))}
      />
      <Accordion
        title="Visibility"
        items={uniqVisibility.map((visibility) => (
          <FilterOption
            key={visibility}
            filterName={visibility}
            count={contactVisibility.filter((v) => v === visibility).length}
            isSelected={globalFilter.visibility === visibility}
            onClick={() => handleChangeAdvancedFilter({ visibility })}
          />
        ))}
      />
    </Filter>
  )
}

export const initialContactsGlobalFilter = {
  status: [],
  feature_profile: [],
  search: '',
  cabinet: '',
  role: '',
  visibility: '',
}
export const contactsGlobalFilter = (
  rows: Row<Contact>[],
  columnIds: string[],
  filterValue: ContactsGlobalFilter
): Row<Contact>[] => {
  let remainingRows = [...rows]
  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { firstname, lastname, info, identity, email_work, organization } = r.original
      if (isIncludeFields([firstname, lastname, info, identity, email_work, organization], regExp)) {
        return true
      }
      return false
    })
  }

  if (filterValue.cabinet) {
    remainingRows = remainingRows.filter((r) => {
      const { info } = r.original
      const { cabinet } = parseInfoField(info)
      return cabinet === filterValue.cabinet
    })
  }

  if (filterValue.role) {
    remainingRows = remainingRows.filter((r) => {
      const { info } = r.original
      const { role } = parseInfoField(info)
      return role === filterValue.role
    })
  }

  if (filterValue.visibility) {
    remainingRows = remainingRows.filter((r) => {
      const { visible } = r.original
      return filterValue.visibility === (visible ? 'Visible' : 'Hidden')
    })
  }

  return remainingRows
}
