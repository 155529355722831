import React from 'react'
import styled from 'styled-components'
import { GreyParagraph2 } from './Paragraph'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string
  type?: 'text' | 'email' | 'password' | 'number' | 'date' | 'datetime-local'
  value?: string | number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  disabled?: boolean
  maxLength?: number
  required?: boolean
}

export default function Input({
  placeholder,
  value,
  onChange,
  type = 'text',
  className,
  disabled,
  maxLength,
  required,
  ...rest
}: Props) {
  return (
    <Container
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      type={type}
      className={className}
      disabled={disabled}
      maxLength={maxLength}
      required={required}
      {...rest}
    />
  )
}

const Container = styled.input`
  padding: 12px 15px;
  line-height: 20px;
  flex: 1;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 14px;
  font-family: 'Open Sans';
  border: 1px solid #b9b9b9;
`

export const TextArea = styled.textarea`
  padding: 8px 15px;
  padding-right: 50px;
  flex: 1;
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Open Sans';
  border: 1px solid #b9b9b9;
  resize: none;
  min-height: 80px;
  width: 100%;
`

///////////////
export function InputCounter(props: { length: number; max: number; children: React.ReactNode }) {
  return (
    <InputCounterContainer>
      {props.children}
      <div className="remaining">
        <GreyParagraph2 text={`${props.max - props.length} characters left`} size="11" />
      </div>
    </InputCounterContainer>
  )
}

const InputCounterContainer = styled.div`
  position: relative;
  .remaining {
    position: absolute;
    right: 10px;
    bottom: 4px;
  }
`

////////////////////////////////////////////////////////////////////////////////////////////

export const FileInputContainer = styled.div`
  display: grid;
  position: relative;
  > input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
`
