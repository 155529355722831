import React from 'react'
import {
  Column, Row,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { EmailSent } from '../../@types'
import { useTranslation } from 'react-i18next'
import Header from '../../components/organisms/Table/atoms/Header'
import { emptyArray, isIncludeFields, sortByAlphabet } from '../../utils/array'
import { BlackParagraph } from '../../components/atoms/Paragraph'
import { highlight } from '../../utils/highlight'
import { useQuery, useQueryClient } from 'react-query'
import { emailResendMailFn, emailSentLogsFn } from '../../api'
import { LoadingPage } from '../../components/molecules/Loader'
import Table from '../../components/organisms/Table'
import { useUserContext } from '../../contexts/UserContext'
import { Dropdown } from '../../components/molecules/Dropdown'
import { IconButton } from '../../components/molecules/Button'
import { Icon } from '@iconify/react'
import { MenuItem, Popup } from '../../components/molecules/Popup'
import { MODALS } from '../../components/organisms/Modal'
import { LinkTo } from '../../components/atoms/Pointer'

const columns: Column<EmailSent>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('page.emails.recipient')} />
    },
    accessor: 'recipient',
    sortType: (a, b) => sortByAlphabet(a.values.recipient, b.values.recipient),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('page.emails.email')} />
    },
    accessor: 'email',
    sortType: (a, b) => sortByAlphabet(a.values.email, b.values.email),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('page.emails.created_at')} />
    },
    accessor: 'created_at',
    sortType: (a, b) => sortByAlphabet(a.values.created_at, b.values.created_at),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('page.emails.sent_at')} />
    },
    accessor: 'sent_at',
    sortType: (a, b) => sortByAlphabet(a.values.sent_at, b.values.sent_at),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => null,
    accessor: 'id',
    id: 'edit',
    maxWidth: 40,
    disableSortBy: true,
    Cell: (cell) => {
      const [t] = useTranslation()
      const queryClient = useQueryClient()
      const { id } = cell.row.original

      return (
        <Dropdown
          button={
            <IconButton>
              <Icon icon="uil:ellipsis-v" />
            </IconButton>
          }
        >
          <Popup>
            <MenuItem value="change-fg" icon={<Icon icon="uil:sync" />} onClick={() => emailResendMailFn(id).then(() => {
              queryClient.invalidateQueries('email_sent_logs')
            }).catch((e) => {})}>
              {t('page.emails.resend')}
            </MenuItem>
            <LinkTo to={`/emails/${id}?modal=${MODALS.viewSentEmail}`}>
              <MenuItem value="change-fg" icon={<Icon icon="uil:edit" />} onClick={() => {}}>
                {t('common.edit')}
              </MenuItem>
            </LinkTo>
          </Popup>
        </Dropdown>
      )
    },
    width: 0,
  },
]


const EmailsSent = () => {
  const { state } = useUserContext()
  const { i_realm } = state?.realm

  const { data: emailSent, isLoading } = useQuery(['email_sent_logs', i_realm], emailSentLogsFn)

  const tableProps = useTable(
    {
      columns,
      data: emailSent || emptyArray,
      initialState: { pageSize: 20, globalFilter: { search: '' } },
      globalFilter: globalFilterFunc,
      autoResetPage: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  if (isLoading || !emailSent) {
    return <LoadingPage />
  }

  return (
    <Table isLoading={isLoading} {...tableProps} />
  )
}

interface GlobalFilter {
  search: string
}

const globalFilterFunc = (rows: Row<EmailSent>[], _columnIds: string[], filterValue: GlobalFilter): Row<EmailSent>[] => {
  let remainingRows = [...rows]

  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { recipient, email } = r.original

      if (isIncludeFields([recipient, email], regExp)) {
        return true
      }
      return false
    })
  }

  return remainingRows
}
export default EmailsSent