import React, { FC } from 'react'
import { ModalProps, ModalView } from '../../../components/organisms/Modal'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Realm } from '../../../@types'
import { getRealmsFn, updateRealmFn } from '../../../api'
import InputWithCaptions, { TextAreaWithCaptions } from '../../../components/molecules/InputWithCaptions'
import styled from 'styled-components'
import Button from '../../../components/molecules/Button'
import Loader from '../../../components/molecules/Loader'
import { GreyParagraph2 } from '../../../components/atoms/Paragraph'


const EditRealm: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const params = useParams<{ id: string }>()
  const realm_id = +params.id!
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<Partial<Realm> & Record<string, any>>()

  const { data: selectedRealm } = useQuery(['realms'], getRealmsFn, {
    select: (data) => data.filter(({ i_realm }) => i_realm === realm_id)?.[0],
  })


  const { mutate: updateRealm } = useMutation((data: Partial<Realm>) => updateRealmFn({ ...selectedRealm, ...data }), {
    onSuccess: () => {
      queryClient.invalidateQueries('realms')
      onClose()
    },
  })

  const onSubmit = (values: Partial<Realm>) => {
    updateRealm(values)
  }

  if (!selectedRealm) {
    return <Loader height="30vh" />
  }

  return (
    <ModalView title={t('modal.editRealm.title')} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Controller
              name="name"
              defaultValue={selectedRealm.name}
              control={control}
              render={({ field: { ref, ...props } }) => (
                <InputWithCaptions type="text" caption={t('common.name')} {...props} />
              )}
            />
            <Controller
              name="domain"
              defaultValue={selectedRealm.domain}
              control={control}
              render={({ field: { ref, ...props } }) => (
                <InputWithCaptions type="text" caption={t('realm.domain')} {...props} />
              )}
            />
          </Field>
          <Field>
            <Controller
              name="css_base_url"
              defaultValue={selectedRealm.css_base_url}
              control={control}
              render={({ field: { ref, ...props } }) => (
                <InputWithCaptions type="text" caption={t('realm.css_base_url')} {...props} />
              )}
            />
            <Controller
              name="xmpp_fallback"
              defaultValue={selectedRealm.xmpp_fallback}
              control={control}
              render={({ field: { ref, ...props } }) => (
                <InputWithCaptions type="text" caption={t('realm.xmpp_fallback')} {...props} />
              )}
            />
          </Field>

          <GreyParagraph2
            style={{ marginLeft: '15px', marginBottom: '5px' }}
            size="11"
            text={t('common.leave_empty_if_not_changed')}
          />
          <hr style={{ width: '100%' }}/>
          <Field>
            <Controller
              name="pub_key"
              defaultValue=""
              control={control}
              render={({ field: { ref, ...props } }) => (
                <TextAreaWithCaptions caption={t('realm.pub_key')} {...props} />
              )}
            />
            <Controller
              name="priv_key"
              defaultValue=""
              control={control}
              render={({ field: { ref, ...props } }) => (
                <TextAreaWithCaptions caption={t('realm.priv_key')} {...props} />
              )}
            />
          </Field>
          <Field>
            <Controller
              name="certificate"
              defaultValue=""
              control={control}
              render={({ field: { ref, ...props } }) => (
                <TextAreaWithCaptions caption={t('realm.certificate')} {...props} />
              )}
            />
          </Field>
          <SubmitButton appearance="blue" type="submit" disabled={!isValid}>
            {t('modal.addRealm.save')}
          </SubmitButton>
        </Form>
      </Content>
    </ModalView>
  )
}

const SubmitButton = styled(Button)`
  margin-top: 32px;
  align-self: flex-end;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`
const Field = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-flow: wrap;
  gap: 12px;
  & > * {
    flex: 1;
    min-width: 180px;
  }
`

export default EditRealm
