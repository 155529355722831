import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { deleteEmailTemplateFn, emailTemplatesFn, updateEmailTemplatesFn } from '../../api'
import {
  Column, Row,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { emptyArray, isIncludeFields, sortByAlphabet } from '../../utils/array'
import { EmailTemplate } from '../../@types'
import { useTranslation } from 'react-i18next'
import Header from '../../components/organisms/Table/atoms/Header'
import { BlackParagraph } from '../../components/atoms/Paragraph'
import { highlight } from '../../utils/highlight'
import { Dropdown } from '../../components/molecules/Dropdown'
import Button, { IconButton } from '../../components/molecules/Button'
import { Icon } from '@iconify/react'
import { MenuItem, Popup } from '../../components/molecules/Popup'
import { LinkTo } from '../../components/atoms/Pointer'
import { MODALS } from '../../components/organisms/Modal'
import { LoadingPage } from '../../components/molecules/Loader'
import Table from '../../components/organisms/Table'
import { useUserContext } from '../../contexts/UserContext'
import { useConfirm } from '../../components/organisms/ConfirmDialog'
import { toast } from 'react-toastify'
import { Switch } from '../../components/molecules/Switch'

const columns: Column<EmailTemplate>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.name')} />
    },
    accessor: 'name',
    sortType: (a, b) => sortByAlphabet(a.values.name, b.values.name),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('page.emails.category')} />
    },
    accessor: 'category',
    sortType: (a, b) => sortByAlphabet(a.values.category, b.values.category),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('realm.default_realm')} />
    },
    accessor: 'default',
    sortType: (a, b) => sortByAlphabet(a.values.default, b.values.default),
    Cell: ({ value, row }) => {
      const [t] = useTranslation()
      const { confirm } = useConfirm()
      const queryClient = useQueryClient()
      const updatedData = { ...row.original, default: !Boolean(value) }

      const { mutate: updateEmailTemplates } = useMutation(updateEmailTemplatesFn, {
        onSuccess: (res) => {
          if (res) {
            queryClient.invalidateQueries(['email_templates'])
          }
        },
      })

      return (
        <Switch
          checked={Boolean(value)}
          onToggle={async () => {
            const isConfirmed = await confirm(
              <p style={{ width: '210px' }}>
                {value ? t('page.apps.reallyDisable') : t('page.apps.reallyEnable')}
              </p>,
              <Button appearance={value ? 'red' : 'outline'}>{value ? t('common.disable') : t('common.enable')}</Button>
            )
            if (isConfirmed) {
              updateEmailTemplates(updatedData)
            }
          }}
        />
      )
    },
  },
  {
    Header: () => null,
    accessor: 'id',
    id: 'edit',
    maxWidth: 40,
    disableSortBy: true,
    Cell: (cell) => {
      const [t] = useTranslation()
      const { id } = cell.row.original

      const queryClient = useQueryClient()
      const { confirm } = useConfirm()

      const { mutate: deleteEmailTemplate } = useMutation(deleteEmailTemplateFn, {
        onError: () => {
          toast.error(t('common.deleteError'))
        },
        onSuccess: (a, b) => {
          queryClient.invalidateQueries(['email_templates'])
          if (b) {
            toast.success(t('modal.editFeatureGroup.successDelete'))
          }
        },
      })

      return (
        <Dropdown
          button={
            <IconButton>
              <Icon icon="uil:ellipsis-v" />
            </IconButton>
          }
        >
          <Popup>
            <LinkTo to={`/emails/${id}?modal=${MODALS.addEditEmailTemplate}`}>
              <MenuItem value="change-fg" icon={<Icon icon="uil:edit" />} onClick={() => {}}>
                {t('common.edit')}
              </MenuItem>
            </LinkTo>
            <MenuItem
              value="delete"
              red
              icon={<Icon icon="uil:trash" />}
              onClick={async () => {
                const isConfirmed = await confirm(
                  <p style={{ width: '210px' }}>
                    {t('modal.editFeatureGroup.confirmDelete')}
                  </p>
                )
                if (isConfirmed) {
                  deleteEmailTemplate(id)
                }
              }}
            >
              {t('common.delete')}
            </MenuItem>
          </Popup>
        </Dropdown>
      )
    },
    width: 0,
  },
]


const EmailTemplates = () => {

  const { state } = useUserContext()
  const { i_realm } = state?.realm

  const { data: emailTemplates, isLoading } = useQuery(['email_templates', i_realm], emailTemplatesFn)

  const tableProps = useTable(
    {
      columns,
      data: emailTemplates || emptyArray,
      initialState: { pageSize: 20, globalFilter: { search: '' } },
      globalFilter: globalFilterFunc,
      autoResetPage: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  if (isLoading || !emailTemplates) {
    return <LoadingPage />
  }

  return (
    <Table isLoading={isLoading} {...tableProps} />
  )
}

interface GlobalFilter {
  search: string
}

const globalFilterFunc = (rows: Row<EmailTemplate>[], _columnIds: string[], filterValue: GlobalFilter): Row<EmailTemplate>[] => {
  let remainingRows = [...rows]

  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { category, name } = r.original

      if (isIncludeFields([category, name], regExp)) {
        return true
      }
      return false
    })
  }

  return remainingRows
}

export default EmailTemplates