import React, { FC, useCallback } from 'react'
import { ActivityLog } from '@types'
import { getActivityLogFn } from 'api'
import { BlackParagraph } from 'components/atoms/Paragraph'
import { LoadingPage } from 'components/molecules/Loader'
import Table from 'components/organisms/Table'
import Header from 'components/organisms/Table/atoms/Header'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
  Column,
  Row,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { emptyArray, isIncludeFields, sortByAlphabet, sortByDate } from 'utils/array'
import { DATE_TIME_FORMAT } from 'utils/date'
import { highlight } from 'utils/highlight'
import Label from 'components/atoms/Label'
import { Page, PageHeader } from 'components/organisms/layout'
import { useUserContext } from 'contexts/UserContext'
import { Roles } from 'utils/consts'
import { LogsFilter } from './components/Filter'

interface FormattedActivityLog extends Omit<ActivityLog, 'admin_user'> {
  adminRole: ActivityLog['admin_user']['type']
}

const columns: Column<FormattedActivityLog>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.date')} />
    },
    accessor: 'created',
    sortType: (a, b) => sortByDate(a.values.created, b.values.created),
    Cell: ({ value }) => {
      if (!value) return <div>-</div>
      const date = dayjs(value).format(DATE_TIME_FORMAT)
      return <BlackParagraph text={date} size="12" />
    },
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.operator')} />
    },
    accessor: 'admin_username',
    sortType: (a, b) => sortByAlphabet(a.values.admin_username, b.values.admin_username),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
    width: 100,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.role')} />
    },
    accessor: 'adminRole',
    sortType: (a, b) => sortByAlphabet(a.values.adminRole, b.values.adminRole),
    Cell: ({ value, state: { globalFilter } }) => (
      <Label
        text={highlight(value, globalFilter.search) as string}
        color={value === Roles.SuperAdmin ? '#d9ae44c2' : '#3a8f5ad4'}
      />
    ),
    width: 120,
  },

  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.origin')} />
    },
    accessor: 'entity_name',
    sortType: (a, b) => sortByAlphabet(a.values.entity_name, b.values.entity_name),
    Cell: ({ value, state: { globalFilter } }) => {
      return <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    },
    width: 100,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.action')} />
    },
    accessor: 'message',
    Cell: ({ value, state: { globalFilter } }) => {
      if (!value) return <div>-</div>
      return (
        <BlackParagraph
          style={{ flexDirection: 'row-reverse', whiteSpace: 'pre-wrap' }}
          text={highlight(value, globalFilter.search)}
          size="12"
        />
      )
    },
    width: 200,
  },
  {
    Header: () => null,
    accessor: 'entity',
    disableSortBy: true,
    Cell: () => {
      return <div />
    },
    width: 0,
  },
]

const generateMessage = ({
  entity_name,
  type,
  admin_username,
  data,
}: {
  entity_name: string
  type: ActivityLog['type']
  admin_username: string
  data: any
}) => {
  const getType = (type: ActivityLog['type']) => {
    switch (type) {
      case 'ADD':
        return 'added'
      case 'DELETE':
        return 'deleted'
      case 'UPDATE':
        return 'updated'
    }
  }

  let title = ''
  title ||= data?.title || data?.firstname || data?.user_id || ''

  return `${entity_name} ${title} ${getType(type)} by ${admin_username}`
}

const initialGlobalFilter = { search: '', actions: [] }

const Log: FC = () => {
  const [t] = useTranslation()
  const { state } = useUserContext()
  const { i_realm } = state?.realm
  const role = state.admin?.type

  const { data: logs, isLoading } = useQuery(['activity-log', i_realm], getActivityLogFn, {
    select: (data) => {
      return data
        .filter(({ admin_user }) => (role === Roles.SuperAdmin ? true : admin_user?.type !== Roles.SuperAdmin))
        .map(({ admin_user, message, ...log }) => ({
          adminRole: admin_user?.type,
          message:
            message ||
            generateMessage({
              entity_name: log.entity_name,
              type: log.type,
              admin_username: log.admin_username,
              data: log.data,
            }),
          ...log,
        }))
    },
  })

  const tableProps = useTable(
    {
      columns,
      data: logs ?? emptyArray,
      initialState: { pageSize: 20, globalFilter: initialGlobalFilter }, // Pass our hoisted table state
      globalFilter: globalFilterFunc,
      autoResetPage: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const globalFilter: GlobalFilter = tableProps.state.globalFilter

  const handleChangeFilter = (option: string) => {
    const filterActions = () =>
      tableProps.setGlobalFilter({
        ...globalFilter,
        actions: globalFilter.actions.filter((action) => action !== option),
      })
    const setActions = () => tableProps.setGlobalFilter({ ...globalFilter, actions: [...globalFilter.actions, option] })

    globalFilter.actions.includes(option) ? filterActions() : setActions()
  }

  const handleResetFilters = () => tableProps.setGlobalFilter({ ...initialGlobalFilter, search: globalFilter.search })

  const handleChangeSearch = useCallback(
    (search: string) => {
      tableProps.setGlobalFilter({ ...globalFilter, search })
    },
    [globalFilter, tableProps]
  )

  if (isLoading || !logs) {
    return <LoadingPage />
  }

  return (
    <Page title={t('page.activity.title')}>
      <PageHeader
        title={t('page.activity.title')}
        subtitle={logs?.length.toString()}
        search={globalFilter.search}
        filter={<LogsFilter globalFilter={globalFilter} onChangeFilter={handleChangeFilter} />}
        isFilterActive={!!globalFilter.actions.length}
        onClearFilters={handleResetFilters}
        onChangeSearch={handleChangeSearch}
      />
      <Table isLoading={isLoading} {...tableProps} />
    </Page>
  )
}

interface GlobalFilter {
  search: string
  actions: string[]
}

const globalFilterFunc = (
  rows: Row<FormattedActivityLog>[],
  _columnIds: string[],
  filterValue: GlobalFilter
): Row<FormattedActivityLog>[] => {
  let remainingRows = [...rows]

  if (filterValue.actions.length) {
    const activeFilters = filterValue.actions.join('|')
    const regExp = new RegExp(`${activeFilters}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { message, entity, type } = r.original
      const isCreate = type === 'ADD'
      const isUpdate = type === 'UPDATE'

      return filterValue.actions.some((action) => {
        switch (action) {
          case 'updateUser':
            return entity === 'Roster' && isUpdate
          case 'createInfoMessage':
            return entity === 'InfoMessage' && isCreate
          case 'updateInfoMessage':
            return entity === 'InfoMessage' && isUpdate
          case 'createFeatureGroup':
            return entity === 'FeatureGroup' && isCreate
          case 'updateFeatureGroup':
            return entity === 'FeatureGroup' && isUpdate
          default:
            return isIncludeFields([message], regExp) ? true : false
        }
      })
    })
  }

  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { admin_username, entity_name, message, adminRole } = r.original

      if (isIncludeFields([admin_username, entity_name, message, adminRole], regExp)) {
        return true
      }
      return false
    })
  }

  return remainingRows
}

export default Log
