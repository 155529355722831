import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Icon from 'components/atoms/Icon'
import Button from 'components/molecules/Button'
import { useQuery, useQueryClient } from 'react-query'
import { createAutoconfigFn, getBundleMappingsFn, getFeatureGroupsFn, validateUserIdentityFn } from 'api'
import { Controller, useForm } from 'react-hook-form'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import { BlackBoldParagraph, GreyParagraph2 } from 'components/atoms/Paragraph'
import ReactSelect from 'components/molecules/ReactSelect'
import { TextArea } from 'components/atoms/Input'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useUserContext } from 'contexts/UserContext'
import { OptionType } from 'components/molecules/ReactSelect'
import { Switch } from '../../../components/molecules/Switch'
import { ErrorMessage } from '@hookform/error-message'
import { isEmpty } from 'lodash'


const AddUser: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const [qrSrc, setQr] = React.useState<string>()
  const [needToValidate, setNeedToValidate] = React.useState<boolean>(() => true)
  const queryClient = useQueryClient()
  const { state } = useUserContext()
  const { i_realm } = state?.realm

  const { data: bundleMappingOptions } = useQuery(['bundle-mappings', i_realm], getBundleMappingsFn, {
    select: (data) =>
      data.map(({ id, qr_app_name, clientbundleid }) => ({ label: `${qr_app_name} | ${clientbundleid}`, value: id })),
  })
  const { data: featureGroupsOptions } = useQuery(['feature-groups', i_realm], getFeatureGroupsFn, {
    select: (data) => data.map(({ name, i_featuregroup }) => ({ label: name, value: i_featuregroup })),
  })

  const EMAIL_VALIDATE_EXPR = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/s

  const defaultBundleId = (bundleMappingOptions as OptionType[] || [])?.find((b: OptionType) => b.label.includes('android'))?.value || ''
  const {
    control,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      errors,
    },
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    reset,
  } = useForm<
    Partial<{
      username: string
      realm: number
      featuregroup: number
      first_name: string
      last_name: string
      info: string
      bundle_mapping_id: number | string
      auto_visible: boolean
      email: string
    }>
  >({
    defaultValues: {
      bundle_mapping_id: defaultBundleId,
      auto_visible: true,
      email: ''
    },
  })

  const {
    username,
    featuregroup,
    first_name,
    last_name,
    info,
    bundle_mapping_id = defaultBundleId,
    auto_visible,
    email
  } = getValues()

  const userIdentityUniqueness = useQuery(
    ['user_identity_uniqueness', username, first_name, last_name, info, email ],
    validateUserIdentityFn,
    {
      enabled: false,
      onError: () => {
        userIdentityUniqueness.refetch()
      }
    }
  );

  const similar_to = userIdentityUniqueness?.data?.similar_to || []
  const fullname_uniqueness = userIdentityUniqueness?.data?.fullname_uniqueness

  const validatingUserIdentity = userIdentityUniqueness.isLoading || userIdentityUniqueness.isRefetching
  useQuery(
    ['create-autoconfig', username, i_realm, featuregroup, first_name, last_name, info, bundle_mapping_id, auto_visible, email],
    createAutoconfigFn,
    {
      enabled: isSubmitSuccessful,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: async (blob) => {
        const file = new File([blob], `qr${blob.size}`, {
          type: blob.type,
        })
        const qrCode = URL.createObjectURL(file)

        setQr(qrCode)
        queryClient.invalidateQueries(['users'])
        queryClient.invalidateQueries(['user_identity_uniqueness'])
      },
      onError: async (err: any) => {
        if (err.response?.data instanceof Blob) {
          const blobResponse = await err.response?.data?.text()
          const blobData = JSON.parse(blobResponse)
          let message = blobData?.reason
          toast.error(message)
        }
        reset()
      },
    }
  )

  useEffect(() => {
    if (!isValid) {
      setNeedToValidate(true)
    }
    if (Object.keys(errors).length > 0 && !needToValidate) {
      setNeedToValidate(true)
    }
  }, [ isValid, errors, needToValidate ])

  const validateUserIdentity = async () => {
    await queryClient.invalidateQueries(['user_identity_uniqueness'])
    const { data, remove } = await userIdentityUniqueness.refetch({})
    if (!data) {
      remove()
      await validateUserIdentity()
      return
    }
    if (data.user_email_uniqueness === false) {
      setError('email', {
        type: 'manual',
        message: t('modal.addUser.emailNotUnique'),
      })
    } else {
      clearErrors('email')
    }
    if (data.user_id_exists) {
      setError('username', {
        type: 'manual',
        message: t('modal.addUser.usernameNotUnique'),
      })
    } else {
      clearErrors('username')
      setNeedToValidate(false)
    }
  }

  return (
    <ModalView title={t('modal.addUser.add')} onClose={onClose}>
      <Content>
        {isSubmitSuccessful ? (
          <>
            {qrSrc ? (
              <img src={qrSrc} style={{ width: 300 }} alt="qr-code" />
            ) : (
              <div style={{ width: 300, height: 300, border: '1px solid' }}>
                <span>{t('modal.addUser.QRgenerating')}</span>
              </div>
            )}
            <DownloadForm href={qrSrc} download={`${username}.png`}>
              <Button icon={<Icon icon="uil:import" />} appearance="flat">
                {t('modal.addUser.QRdownload')}
              </Button>
            </DownloadForm>
          </>
        ) : (
          <Form onSubmit={async (event) => {
            event.preventDefault()
            if (needToValidate) {
              await validateUserIdentity()
            } else {
              handleSubmit(() => {})(event)
            }

          }}>
            <Field>
              <Controller
                name="username"
                defaultValue=""
                rules={{
                  required: true,
                  minLength: 3,
                  maxLength: 24,
                  onChange: async (event) => {
                    if (!/^[a-zA-Z0-9+_.-]+$/.test(event.target.value)) {
                      setError('username', {
                        type: 'manual',
                        message: t('modal.addUser.usernameInvalid'),
                      })
                    }
                    await queryClient.invalidateQueries(['user_identity_uniqueness'])
                    setNeedToValidate(true)
                  }
               }}
                control={control}
                render={({ field: { ref, ...props } }) => (
                  <InputWithCaptions type="text" caption={t('common.username') + " *"} {...props}>
                    <ErrorMessage errors={errors} as={'div'} name="username" className={'error-message'} />
                    {
                      (userIdentityUniqueness.data?.was_used || []).length > 0 && (
                        <GreyParagraph2
                          className={'warning-message-block'}
                          size="11"
                          text={t('modal.addUser.wasUsed')}
                        />
                      )
                    }
                  </InputWithCaptions>
                )}
              />
              <div>
                <GreyParagraph2
                  style={{ marginLeft: '15px', marginBottom: '5px' }}
                  size="11"
                  text={t('common.featureGroup') + " *"}
                />
                <Controller
                  name="featuregroup"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, onChange, value, ...props } }) => {
                    return (
                      <ReactSelect
                        value={featureGroupsOptions?.filter((g) => g.value === value)}
                        onChange={({ value }) => {
                          onChange(value)
                        }}
                        isSearchable={false}
                        options={featureGroupsOptions}
                        placeholder={t('placeholder.chooseFeatureGroup')}
                        {...props}
                      />
                    )
                  }}
                />
              </div>
            </Field>

            <Field>
              <Controller
                name="first_name"
                defaultValue=""
                control={control}
                rules={{
                  onChange: async () => {
                    await queryClient.invalidateQueries(['user_identity_uniqueness'])
                    setNeedToValidate(true)
                  }
                }}
                render={({ field: { ref, ...props } }) => (
                  <InputWithCaptions type="text" caption={t('common.firstName')} {...props} />
                )}
              />
              <Controller
                name="last_name"
                defaultValue=""
                control={control}
                rules={{
                  onChange: async () => {
                    await queryClient.invalidateQueries(['user_identity_uniqueness'])
                    setNeedToValidate(true)
                  }
                }}
                render={({ field: { ref, ...props } }) => (
                  <InputWithCaptions type="text" caption={t('common.lastName')} {...props} />
                )}
              />
            </Field>

            {
              (fullname_uniqueness !== undefined && !fullname_uniqueness) && (
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-10px' }}>
                  <div className={'warning-message-block'}>{t('modal.addUser.fullNameNotUnique')}</div>
                </div>
              )
            }

            <Field>
              <Controller
                name="email"
                defaultValue=""
                control={control}
                rules={{
                  onChange: async (e) => {
                    await queryClient.invalidateQueries(['user_email_uniqueness']);
                    if (isEmpty(e.target.value)) {
                      clearErrors('email')
                    } else {
                      if (!EMAIL_VALIDATE_EXPR.test(e.target.value) && e.target.value.length > 0) {
                        setError('email', {
                          type: 'manual',
                          message: t('modal.addUser.emailInvalid'),
                        })
                      } else {
                        clearErrors('email')
                      }
                    }
                    setNeedToValidate(true);
                  }
                }}
                render={({ field: { ref, ...props } }) => (
                  <InputWithCaptions
                    type="email"
                    caption={t('common.email')}
                    {...props}>

                    <ErrorMessage errors={errors} as={'div'} name="email" className={'error-message'} />
                  </InputWithCaptions>
                )}
              />
              <Controller
                name="bundle_mapping_id"
                defaultValue=""
                control={control}
                render={({ field: { ref, onChange, value, ...props } }) => {
                  return (
                    <div style={{ display: 'none' }}>
                      <ReactSelect
                        value={bundleMappingOptions?.filter((g) => {
                          return g.value === +value!
                        })}
                        onChange={({ value }) => {
                          onChange(value)
                        }}
                        isSearchable={false}
                        options={bundleMappingOptions}
                        placeholder={t('placeholder.chooseBundle')}
                        {...props}
                      />
                    </div>
                  )
                }}
              />
            </Field>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <GreyParagraph2 style={{ marginLeft: '15px', marginBottom: '5px' }} size="11" text={t('common.info')} />
              <Controller
                name="info"
                defaultValue=""
                control={control}
                render={({ field: { ref, value, ...props } }) => <TextArea rows={4} value={value} {...props} />}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
              <BlackBoldParagraph text={t('common.visible')} />
              <Controller
                name="auto_visible"
                defaultValue={true}
                control={control}
                render={({ field: { ref, value, onChange, ...props } }) => <Switch checked={value} {...props} onToggle={onChange} />}
              />
            </div>

            {
              similar_to.length > 0 && (
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                  <GreyParagraph2 text={t('modal.addUser.similarTo')} />
                  <ul className={'warning-message-block'}>
                    {
                      similar_to.map((similarTo: string) => (
                        <li key={similarTo}>{similarTo}</li>
                      ))
                    }
                  </ul>
                </div>
              )
            }

            <Button
              style={{ alignSelf: 'flex-end', marginTop: '12px' }}
              appearance="blue"
              type="submit"
              onClick={() => {
              }}
              disabled={!isDirty || !isValid}
            >
              {
                validatingUserIdentity
                  ? t('modal.addUser.validating')
                  : ( needToValidate ? t('modal.addUser.check') : t('modal.addUser.add') )
              }
            </Button>
          </Form>
        )}
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const DownloadForm = styled.a`
    display: contents;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`
const Field = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-flow: wrap;
  gap: 12px;
  & > * {
    flex: 1;
    min-width: 180px;
  }
`

export default AddUser
