import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import Button from 'components/molecules/Button'
import { ModalProps, ModalView } from '../../../components/organisms/Modal'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { RealmMailSettings } from '../../../@types'
import InputWithCaptions from '../../../components/molecules/InputWithCaptions'
import { ErrorMessage } from '@hookform/error-message'
import { BlackBoldParagraph } from '../../../components/atoms/Paragraph'
import { Switch } from '../../../components/molecules/Switch'
import { useParams } from 'react-router-dom'
import {
  createRealmMailSettingsFn,
  getRealmMailSettingsFn,
  updateRealmMailSettingsFn,
} from '../../../api'
import Loader from '../../../components/molecules/Loader'


interface FormProps {
  i_realm: number
  username: string
  password: string
  mail_from: string
  from_name: string
  port: number
  server: string
  ssl_tls: boolean
  starttls: boolean
  validate_certs: boolean
}

const AddEditRealMailSettings: FC<ModalProps> = ({ onClose })  => {

  const [t] = useTranslation()
  const queryClient = useQueryClient()

  const params = useParams<{ id: string }>()
  const i_realm = +params.id!

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<FormProps>()

  const { data: selectedRealmMailSettings } = useQuery(['realm_mail_settings', i_realm], getRealmMailSettingsFn, {
    select: (data) => data || {
      i_realm,
      username: '',
      password: '',
      mail_from: '',
      from_name: '',
      port: 475,
      server: '',
      ssl_tls: true,
      starttls: false,
      validate_certs: false
    },
  })

  const { mutate: createRealmMailSettings } = useMutation((data: Partial<RealmMailSettings>) => createRealmMailSettingsFn({ ...data }), {
    onSuccess: () => {
      queryClient.invalidateQueries('realm_mail_settings')
      onClose()
    },
  })

  const { mutate: updateRealmMailSettings } = useMutation((data: Partial<RealmMailSettings>) => updateRealmMailSettingsFn({ ...data }), {
    onSuccess: () => {
      queryClient.invalidateQueries('realm_mail_settings')
      onClose()
    },
  })

  const onSubmit = (values: Partial<RealmMailSettings>) => {
    const requestData = {
      ...values,
      i_realm,
      id: selectedRealmMailSettings?.id,
    }
    if (requestData?.id) {
      updateRealmMailSettings(requestData)
    } else {
      createRealmMailSettings(requestData)
    }
  }

  if (!selectedRealmMailSettings) {
    return <Loader height="30vh" />
  }

  return (
    <ModalView title={t('modal.addRealm.realmMailSettings')} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Controller
              name="username"
              defaultValue={selectedRealmMailSettings.username}
              control={control}
              render={({ field: { ref, ...props } }) => (
                <InputWithCaptions type="text" caption={t('common.username')} {...props} >
                  <ErrorMessage errors={errors} as={'div'} name="username" className={'error-message'} />
                </InputWithCaptions>
              )}
            />
            <Controller
              name="password"
              defaultValue={selectedRealmMailSettings.password}
              control={control}
              render={({ field: { ref, ...props } }) => (
                <InputWithCaptions type="text" caption={t('common.password')} {...props} >
                  <ErrorMessage errors={errors} as={'div'} name="password" className={'error-message'} />
                </InputWithCaptions>
              )}
            />
          </Field>
          <Field>
            <Controller
              name="mail_from"
              defaultValue={selectedRealmMailSettings.mail_from}
              //rules={{ required: true }}
              control={control}
              render={({ field: { ref, ...props } }) => (
                <InputWithCaptions type="text" caption={t('page.realms.mail_from')} {...props} >
                  <ErrorMessage errors={errors} as={'div'} name="mail_from" className={'error-message'} />
                </InputWithCaptions>
              )}
            />
            <Controller
              name="from_name"
              defaultValue={selectedRealmMailSettings.from_name}
              //rules={{ required: true }}
              control={control}
              render={({ field: { ref, ...props } }) => (
                <InputWithCaptions type="text" caption={t('page.realms.from_name')} {...props} >
                  <ErrorMessage errors={errors} as={'div'} name="from_name" className={'error-message'} />
                </InputWithCaptions>
              )}
            />
          </Field>
          <Field>
            <Controller
              name="server"
              defaultValue={selectedRealmMailSettings.server}
              control={control}
              //rules={{ required: true }}
              render={({ field: { ref, ...props } }) => (
                <InputWithCaptions type="text" caption={t('page.realms.server')} {...props} >
                  <ErrorMessage errors={errors} as={'div'} name="server" className={'error-message'} />
                </InputWithCaptions>
              )}
            />
            <Controller
              name="port"
              defaultValue={selectedRealmMailSettings.port}
              //rules={{ required: true }}
              control={control}
              render={({ field: { ref, ...props } }) => (
                <InputWithCaptions type="number" caption={t('page.realms.port')} {...props} >
                  <ErrorMessage errors={errors} as={'div'} name="port" className={'error-message'} />
                </InputWithCaptions>
              )}
            />
          </Field>
          <Field>
            <Controller
              name="ssl_tls"
              control={control}
              defaultValue={Boolean(selectedRealmMailSettings.ssl_tls)}
              render={({ field: { value, onChange } }) => (
                <div
                  style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                >
                  <BlackBoldParagraph text={t('page.realms.ssl_tls')} />
                  <Switch checked={value} onToggle={onChange} />
                </div>
              )}
            />
            <div>
              <Controller
                name="starttls"
                control={control}
                defaultValue={Boolean(selectedRealmMailSettings.starttls)}
                render={({ field: { value, onChange } }) => (
                  <div
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                  >
                    <BlackBoldParagraph text={t('page.realms.starttls')} />
                    <Switch checked={value} onToggle={onChange} />
                  </div>
                )}
              />
            </div>
            <Controller
              name="validate_certs"
              control={control}
              defaultValue={Boolean(selectedRealmMailSettings.validate_certs)}
              render={({ field: { value, onChange } }) => (
                <div
                  style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                >
                  <BlackBoldParagraph text={t('page.realms.validate_certs')} />
                  <Switch checked={value} onToggle={onChange} />
                </div>
              )}
            />
          </Field>
          <SubmitButton appearance="blue" type="submit" disabled={!isValid}>
            {t('modal.addRealm.save')}
          </SubmitButton>
        </Form>
      </Content>
    </ModalView>
  )
}

const SubmitButton = styled(Button)`
  margin-top: 32px;
  align-self: flex-end;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`
const Field = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-flow: wrap;
  gap: 12px;
  & > * {
    flex: 1;
    min-width: 180px;
  }
`

export default AddEditRealMailSettings