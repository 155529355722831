import React, { FC } from 'react'
import { ModalProps, ModalView } from '../../../components/organisms/Modal'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { crateEmailTemplatesFn, emailTemplatesFn, updateEmailTemplatesFn } from '../../../api'
import { useParams } from 'react-router-dom'
import { find } from 'lodash'
import { useUserContext } from '../../../contexts/UserContext'
import Loader from '../../../components/molecules/Loader'
import ReactSelect from '../../../components/molecules/ReactSelect'
import { BlackBoldParagraph } from '../../../components/atoms/Paragraph'
import { Switch } from '../../../components/molecules/Switch'
import { EmailTemplate, EmailTemplateTranslation } from '../../../@types'
import InputWithCaptions from '../../../components/molecules/InputWithCaptions'
import { TextArea } from '../../../components/atoms/Input'
import Tabs, { Tab } from '../../../components/molecules/Tabs'
import Button from '../../../components/molecules/Button'
import defaultActivationTemplate from './defaultActivationTemplate'


const LOCALES = [
  {
    code: 'en',
    order: 1,
    name: 'English',
  },
  {
    code: 'pt',
    order: 2,
    name: 'Portuguese (Portugal)',
  },
]

const AddEditEmailTemplate: FC<ModalProps> = ({ onClose }) => {

  const [t] = useTranslation()
  const params = useParams<{ id: string }>()
  const email_template_id = +params.id!

  const [currentTab, setCurrentTab] = React.useState("0")

  const { state } = useUserContext()
  const { i_realm } = state?.realm

  const categories = ['Activation'].map((category) => ({ label: category, value: category }))
  const queryClient = useQueryClient()

  const { data: selectedEmailTemplate } = useQuery(['email_templates', i_realm], emailTemplatesFn, {
    select: (data) => find(data, (i) => i.id === email_template_id ) || {
        i_realm,
        category: 'Activation',
        name: '',
        default: false,
        translations: LOCALES.map((locale) => ({
          language: locale.code,
          subject: 'Welcome to the platform',
          body: defaultActivationTemplate,
        })) as Partial<EmailTemplateTranslation>[],
      },
    })

  const { mutate: updateEmailTemplate } = useMutation((data: EmailTemplate) => updateEmailTemplatesFn(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('email_templates')
      onClose()
    },
  })

  const { mutate: createEmailTemplate } = useMutation((data: Partial<EmailTemplate>) => crateEmailTemplatesFn(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('email_templates')
      onClose()
    },
  })

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<EmailTemplate>({
    defaultValues: selectedEmailTemplate,
  })

  const { fields } = useFieldArray({
    control,
    name: 'translations', // Name of the field array
  })

  const onSubmit = (data: any) => {
    if (!data?.id) {
      createEmailTemplate(data)
    } else {
      updateEmailTemplate(data)
    }
  }

  const renderEmailTemplate = (index: string) => {
    const formField = fields[+index] || fields[0]

    return (
      <div key={formField.id + index}>
        <Controller
          // @ts-ignore Type '`translations[${string}].body`' is not assignable to type '"default"
          name={`translations[${index}].subject`}
          control={control}
          defaultValue={formField.subject}
          // @ts-ignore Type '`translations[${string}].body`' is not assignable to type '"default"
          render={({ field: { ref, ...props } }) => (<InputWithCaptions type="text" caption={t('page.emails.subject')} {...props} />)}
        />
        <br />
        <Controller
          // @ts-ignore Type '`translations[${string}].body`' is not assignable to type '"default"
          name={`translations[${index}].body`}
          control={control}
          defaultValue={formField.body}
          // @ts-ignore Type '`translations[${string}].body`' is not assignable to type '"default"
          render={({ field: { ref, value, ...props } }) => <TextArea rows={20} value={value} {...props} />}
        />
      </div>
    )
  }

  if (!selectedEmailTemplate) {
    return <Loader height="30vh" />
  }

  return (
    <ModalView title={t('modal.addEditEmailTemplate.title')} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Controller
              name="category"
              defaultValue={selectedEmailTemplate.category}
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, onChange, value, ...props } }) => {
                return (
                  <ReactSelect
                    value={categories.find((category) => category.value === value)}
                    onChange={({ value }) => {
                      onChange(value)
                    }}
                    isSearchable={false}
                    options={categories}
                    placeholder={t('placeholder.chooseFeatureGroup')}
                    {...props}
                  />
                )
              }}
            />
            <div>
              <Controller
                name="default"
                control={control}
                defaultValue={Boolean(selectedEmailTemplate.default)}
                render={({ field: { value, onChange } }) => (
                  <div
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                  >
                    <BlackBoldParagraph text={t('realm.default_realm')} />
                    <Switch checked={value} onToggle={onChange} />
                  </div>
                )}
              />
            </div>
          </Field>
          <Field>
            <Controller
              name="name"
              defaultValue={selectedEmailTemplate.name}
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...props } }) => (
                <InputWithCaptions type="text" caption={t('common.name') + '*'} {...props} />
              )}
            />
          </Field>
          <Tabs activeTab={String(currentTab)} onSwitch={setCurrentTab}>
            {
              fields.map((_, index) => (
                <Tab key={`email_template_${index}`} label={LOCALES[index]?.name} value={String(index)} />
              ))
            }
          </Tabs>

          {
            renderEmailTemplate(currentTab)
          }

          {/*
            fields.map((field, index) => (
              <div key={field.id + index}>
                <GreyParagraph2
                  style={{ marginLeft: '15px', marginBottom: '5px' }}
                  size="11"
                  text={find(LOCALES, { code: field.language })?.name}
                />
                <hr style={{ width: '100%' }}/>
                <Controller
                  // @ts-ignore
                  name={`translations[${index}].subject`}
                  control={control}
                  defaultValue={field.subject}
                  // @ts-ignore
                  render={({ field: { ref, ...props } }) => (<InputWithCaptions type="text" caption={t('common.username')} {...props} />)}
                />
                <br/>
                <Controller
                  // @ts-ignore
                  name={`translations[${index}].body`}
                  control={control}
                  defaultValue={field.body}
                  // @ts-ignore
                  render={({ field: { ref, value, ...props } }) => <TextArea rows={4} value={value} {...props} />}
                />
              </div>
            ))
          */}
          <SubmitButton appearance="blue" type="submit" disabled={!isValid}>
            {t('modal.addRealm.save')}
          </SubmitButton>
        </Form>
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Form = styled.form`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
`
const Field = styled.div`
    display: flex;
    flex-flow: wrap;
    margin-bottom: 24px;
    gap: 12px;

    & > * {
        flex: 1;
    }
`

const SubmitButton = styled(Button)`
  margin-top: 32px;
  align-self: flex-end;
`


export default AddEditEmailTemplate