import React, { FC, useCallback, useRef } from 'react'
import DotAndText from 'components/atoms/DotAndText'
import Icon from 'components/atoms/Icon'
import { BlackParagraph } from 'components/atoms/Paragraph'
import { LinkTo } from 'components/atoms/Pointer'
import Table from 'components/organisms/Table'
import Header from 'components/organisms/Table/atoms/Header'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  Column,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { toast } from 'react-toastify'
import { arrayToCSV, emptyArray, sortByAlphabet, sortByDate } from 'utils/array'
import { highlight } from 'utils/highlight'
import { DATE_TIME_FORMAT } from 'utils/date'
import Tabs, { Tab } from 'components/molecules/Tabs'
import { MODALS } from 'components/organisms/Modal'
import { Dropdown } from 'components/molecules/Dropdown'
import { MenuItem, Popup } from 'components/molecules/Popup'
import { IconButton } from 'components/molecules/Button'
import Button from 'components/molecules/Button'
import { deleteUserFn, getFeatureGroupsFn, getUsersFn, importUsersFn } from 'api'
import { useConfirm } from 'components/organisms/ConfirmDialog'
import { Page, PageHeader } from 'components/organisms/layout'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { UserWithFG, UsersGlobalFilter } from './types'
import { useUserContext } from 'contexts/UserContext'
import { Roles } from 'utils/consts'
import { downloadCsv } from 'utils/download'
import { prepareUsersCsvData } from './utils'
import { AdvancedFilters, usersGlobalFilter } from './components'

const columns: Column<UserWithFG>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.user')} />
    },
    accessor: 'name',
    sortType: (a, b) => sortByAlphabet(a.values.name, b.values.name),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.status')} />
    },
    accessor: 'id',
    sortType: (a, b) => sortByDate(a.values.last_login, b.values.last_login),
    Cell: ({ row }) => {
      const isActive = Boolean(row.original.last_login)
      const [t] = useTranslation()

      return (
        <DotAndText
          text={isActive ? t('common.active') : t('common.notActive')}
          color={isActive ? '#499976' : '#d06d6e'}
        />
      )
    },
    width: 60,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.created')} />
    },
    accessor: 'created',
    sortType: (a, b) => sortByDate(a.values.created, b.values.created),
    Cell: ({ value }) => {
      const date = value ? dayjs(value as string).format(DATE_TIME_FORMAT) : '-'
      return <BlackParagraph text={date} size="12" />
    },
    width: 100,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.lastLogin')} />
    },
    accessor: 'last_login',
    sortType: (a, b) => sortByDate(a.values.last_login, b.values.last_login),
    Cell: ({ value }) => {
      if (!value) return <div>-</div>
      const date = dayjs(value).format(DATE_TIME_FORMAT)
      return <BlackParagraph text={date} size="12" />
    },
    width: 100,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.featureGroup')} />
    },
    accessor: 'featureGroup',
    sortType: (a, b) => sortByAlphabet(a.values.featureGroup, b.values.featureGroup),
    Cell: ({ value, state: { globalFilter } }) =>
      !value ? <div>-</div> : <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />,
    width: 140,
  },
  {
    Header: () => null,
    accessor: 'name',
    id: 'edit',
    Cell: (cell) => {
      const [t] = useTranslation()
      const { name, customer_id, last_login, i_realm } = cell.row.original
      const isActive = Boolean(last_login)

      const queryClient = useQueryClient()
      const { confirm } = useConfirm()

      const { mutate: deleteUser } = useMutation(deleteUserFn, {
        onError: () => {
          toast.error(t('page.users.deleteError', { name }))
        },
        onSuccess: (a, b) => {
          queryClient.invalidateQueries(['users'])
          if (b) {
            toast.success(t('page.users.deleteSuccess', { name }))
          }
        },
      })

      return (
        <Dropdown
          button={
            <IconButton>
              <Icon icon="uil:ellipsis-v" />
            </IconButton>
          }
        >
          <Popup>
            {isActive && (
              <LinkTo to={`/users/${customer_id}?modal=${MODALS.editUser}`}>
                <MenuItem value="change-fg" icon={<Icon icon="uil:edit" />} onClick={() => {}}>
                  {t('common.edit')}
                </MenuItem>
              </LinkTo>
            )}
            <MenuItem
              value="delete"
              red
              icon={<Icon icon="uil:trash" />}
              onClick={async () => {
                const isConfirmed = await confirm(
                  <p style={{ width: '210px' }}>
                    {t('page.users.reallyDelete')} <b>{name}</b>
                  </p>
                )
                if (isConfirmed) {
                  deleteUser({ username: name, i_realm })
                }
              }}
            >
              {t('common.delete')}
            </MenuItem>
          </Popup>
        </Dropdown>
      )
    },
    width: 30,
    disableSortBy: true,
  },
]

const Users: FC = () => {
  const [t] = useTranslation()
  const { state } = useUserContext()
  const { i_realm } = state?.realm
  const role = state.admin?.type
  const [storedStatus, setStatusToStorage] = useLocalStorage('users-status')
  const initialGlobalFilter = {
    feature_profile: [],
    search: '',
    status: storedStatus || 'all',
    OS: '',
    version: '',
    lastLogin: '',
  }

  const uploadRef = useRef<HTMLInputElement|null>(null);

  const { data: featureGroups } = useQuery(['feature-groups', i_realm], getFeatureGroupsFn)

  const { data: users, isLoading } = useQuery(['users', i_realm], getUsersFn, {
    select: (data) =>
      data
        .map(({ i_featuregroup, ...rest }) => ({
          featureGroup: featureGroups?.find(({ i_featuregroup: id }) => i_featuregroup === id)?.name,
          ...rest,
        }))
        .sort((a, b) => sortByDate(a.activation_created, b.activation_created)) as UserWithFG[],
  })

  const tableProps = useTable(
    {
      columns,
      data: users ?? emptyArray,
      initialState: { pageSize: 20, globalFilter: initialGlobalFilter },
      globalFilter: usersGlobalFilter,
      autoResetPage: false,
      autoResetGlobalFilter: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )
  const filteredRows = tableProps.globalFilteredRows.map(({ original }) => original)

  const globalFilter: UsersGlobalFilter = tableProps.state.globalFilter

  const switchStatus = useCallback(
    (status: string) => {
      tableProps.setGlobalFilter({ ...globalFilter, status })
      setStatusToStorage(status)
    },
    [globalFilter, setStatusToStorage, tableProps]
  )

  const handleChangeSearch = useCallback(
    (search: string) => {
      tableProps.setGlobalFilter({ ...globalFilter, search })
      tableProps.gotoPage(0)
    },
    [globalFilter, tableProps]
  )

  const handleExportCsv = () => {
    const preparedData = prepareUsersCsvData(filteredRows, featureGroups)
    const csvData = arrayToCSV(preparedData)
    downloadCsv(`users[${dayjs(new Date()).format('DD-MM-HHmmss')}].csv`, csvData)
  }

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e?.target?.files || [])[0];
    e.preventDefault();
    if (!file) return;

    if (uploadRef.current) {
      uploadRef.current.value = "";
      uploadRef.current.type = "text";
      uploadRef.current.type = "file";
    }
    try {
      await importUsersFn(file, i_realm)
    } catch (err: any) {
      // @ts-ignore
      toast.error(err?.response?.data?.reason || 'Failed to import file!')
    }
  }

  const handleImportCsv = () => {
    uploadRef?.current?.click()
  }

  return (
    <Page
      title={t('page.users.title')}
      filter={
        <AdvancedFilters
          filteredRows={filteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={tableProps.setGlobalFilter}
        />
      }
    >
      <input type="file" ref={uploadRef} style={{ display: 'none' }} onChange={uploadFile} />
      <PageHeader
        title={t('page.users.title')}
        subtitle={tableProps?.rows?.length.toString()}
        search={globalFilter.search}
        onChangeSearch={handleChangeSearch}
        onExportCsv={handleExportCsv}
        onImportCsv={handleImportCsv}
        actions={
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              flexWrap: 'wrap-reverse',
              gap: '8px',
            }}
          >
            <Tabs activeTab={globalFilter.status} onSwitch={switchStatus}>
              <Tab label={t('common.all')} value="all" />
              <Tab label={t('common.active')} value="active" />
              <Tab label={t('common.pending')} value="pending" />
              <Tab label={t('Test')} value="test" hidden={role !== Roles.SuperAdmin} />
            </Tabs>
            <div style={{ display: 'flex' }}>
              <LinkTo to={`/users?modal=${MODALS.deleteUser}`}>
                <Button icon={<Icon icon="uil:trash" />} appearance="red">
                  {t('page.users.delete')}
                </Button>
              </LinkTo>
              <LinkTo to={`/users?modal=${MODALS.addUser}`}>
                <Button icon={<Icon icon="uil:plus" />} appearance="blue" style={{ marginLeft: '12px' }}>
                  {t('page.users.add')}
                </Button>
              </LinkTo>
            </div>
          </div>
        }
      />
      <Table isLoading={isLoading} initialFilter={initialGlobalFilter} {...tableProps} />
    </Page>
  )
}

export default Users
