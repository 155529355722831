import React, { FC, useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Button from 'components/molecules/Button'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import { useContacts, useUpdateContact } from '../useContacts'
import { Switch } from 'components/molecules/Switch'
import { BlackBoldParagraph } from 'components/atoms/Paragraph'
import Loader from 'components/molecules/Loader'
import { useTranslation } from 'react-i18next'
import { useUserContext } from 'contexts/UserContext'

const EditContact: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const params = useParams<{ id: string }>()

  const { state: context } = useUserContext()
  const { i_realm } = context?.realm
  const { data: contacts } = useContacts(i_realm)
  const contact = contacts?.find(({ id }) => +params.id! === id)!

  const [state, dispatch] = useReducer(reducer, contact)

  const onChange = (name: string) => (e: React.ChangeEvent<any>) =>
    dispatch({ type: 'CHANGE_PROP', payload: { name, value: e.currentTarget.value } })

  const onSwitch = (name: string) => (value?: boolean) => dispatch({ type: 'CHANGE_PROP', payload: { name, value } })

  const { mutate } = useUpdateContact()

  const onSubmit = () => mutate(state)

  useEffect(() => {
    if (contact) {
      const { id, firstname, lastname, identity, info, visible, organization, email_work } = contact
      const initialValues = { id, firstname, lastname, identity, info, visible, organization, email_work }
      dispatch({ type: 'SET_STATE', payload: initialValues })
    }
  }, [contact])

  if (!state) {
    return <Loader height="30vh" />
  }

  return (
    <ModalView title={t('modal.editContact.title')} onClose={onClose}>
      <Form>
        <Field>
          <InputWithCaptions
            value={state.firstname}
            onChange={onChange('firstname')}
            type="text"
            caption={t('common.firstName')}
          />
          <InputWithCaptions
            value={state.lastname}
            onChange={onChange('lastname')}
            type="text"
            caption={t('common.lastName')}
          />
        </Field>
        <Field>
          <InputWithCaptions
            value={state.identity}
            onChange={onChange('identity')}
            type="text"
            caption={t('common.identity')}
            disabled
          />
          <InputWithCaptions value={state.info} onChange={onChange('info')} type="text" caption={t('common.info')} />
        </Field>

        <Field>
          <InputWithCaptions value={state.organization} onChange={onChange('organization')} type="text" caption={t('common.organization')} />
          <InputWithCaptions value={state.email_work} onChange={onChange('email_work')} type="text" caption={t('common.email_work')} />
        </Field>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
            {
              contact.i_userid !== 0 && (
                <>
                  <BlackBoldParagraph text={t('common.visible')} />
                  <Switch checked={state.visible} onToggle={onSwitch('visible')} />
                </>
              )
        }
        </div>
        <Button appearance="blue" onClick={onSubmit}>
          {t('common.submit')}
          </Button>
        </div>
      </Form>
    </ModalView>
  )
}

const Form = styled.section`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  width: 100%;
`

const Field = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-flow: wrap;
  gap: 12px;
  & > * {
    flex: 1;
    min-width: 180px;
  }
`

interface State {
  firstname: string
  lastname: string
  identity: string
  info: string
  visible: boolean
  organization: string
  email_work: string
}

function reducer(state: State, action: { type: string; payload: any }): State {
  switch (action.type) {
    case 'SET_STATE':
      return action.payload

    case 'CHANGE_PROP':
      return { ...state, [action.payload.name]: action.payload.value }

    default:
      return state
  }
}

export default EditContact
