import React, { useCallback } from 'react'
import { BlackParagraph } from 'components/atoms/Paragraph'
import Header from 'components/organisms/Table/atoms/Header'
import { useTranslation } from 'react-i18next'
import {
  Column,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { arrayToCSV, emptyArray, sortByAlphabet, sortByDate } from 'utils/array'
import { highlight } from 'utils/highlight'
import Icon from 'components/atoms/Icon'
import { LinkTo } from 'components/atoms/Pointer'
import { IconButton } from 'components/molecules/Button'
import { Dropdown } from 'components/molecules/Dropdown'
import { MenuItem, Popup } from 'components/molecules/Popup'
import Table from 'components/organisms/Table'
import { MODALS } from 'components/organisms/Modal'
import { useContacts } from './useContacts'
import { Contact } from '@types'
import { Page, PageHeader } from 'components/organisms/layout'
import { useUserContext } from 'contexts/UserContext'
import { AdvancedFilters, contactsGlobalFilter, initialContactsGlobalFilter } from './components'
import { ContactsGlobalFilter } from './types'
import dayjs from 'dayjs'
import { downloadCsv } from 'utils/download'
import { prepareContactsCsvData } from './utils'

import { ReactComponent as VisibleSvg } from 'brand/assets/common/Visibility_Visible.svg'
import { ReactComponent as NotVisibleSvg } from 'brand/assets/common/Visibility_Hidden.svg'
import DotAndText from '../../components/atoms/DotAndText'

const columns: Column<Contact>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.visibility')} />
    },
    accessor: 'visible',
    width: 40,
    sortType: (a, b) => sortByDate(a.values.visible, b.values.visible),
    Cell: ({ value, row }) => {
      const Component = ( row.original.i_userid === 0 ? row.original.auto_visible : value)
        ? VisibleSvg
        : NotVisibleSvg
      return <Component />
    },
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.lastName')} />
    },
    accessor: 'lastname',
    sortType: (a, b) => sortByAlphabet(a.values.lastname, b.values.lastname),
    Cell: ({ value, state: { globalFilter } }) => <BlackParagraph text={highlight(value, globalFilter.search)} />,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.firstName')} />
    },
    accessor: 'firstname',
    sortType: (a, b) => sortByAlphabet(a.values.firstname, b.values.firstname),
    Cell: ({ value, state: { globalFilter } }) => <BlackParagraph text={highlight(value, globalFilter.search)} />,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.info')} />
    },
    accessor: 'info',
    sortType: (a, b) => sortByAlphabet(a.values.info, b.values.info),
    Cell: ({ value, state: { globalFilter } }) => <BlackParagraph text={highlight(value, globalFilter.search)} />,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.email_work')} />
    },
    accessor: 'email_work',
    sortType: (a, b) => sortByAlphabet(a.values.email_work, b.values.email_work),
    Cell: ({ value, state: { globalFilter } }) => <BlackParagraph text={highlight(value, globalFilter.search)} />,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.identity')} />
    },
    accessor: 'identity',
    sortType: (a, b) => sortByAlphabet(a.values.identity, b.values.identity),
    Cell: ({ value, state: { globalFilter } }) => <BlackParagraph text={highlight(value, globalFilter.search)} />,
    width: 100,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.status')} />
    },
    accessor: 'id',
    sortType: (a, b) => sortByDate(a.values.last_login, b.values.last_login),
    Cell: ({ row }) => {
      const isActive = Boolean(row.original.i_userid)
      const [t] = useTranslation()

      return (
        <DotAndText
          text={isActive ? t('common.active') : t('common.notActive')}
          color={isActive ? '#499976' : '#d06d6e'}
        />
      )
    },
    width: 60,
  },
  {
    Header: () => null,
    accessor: 'firstname',
    id: 'edit',
    Cell: ({ cell }) => {
      const [t] = useTranslation()
      const { id } = cell.row.original

      return (
        <Dropdown
          button={
            <IconButton>
              <Icon icon="uil:ellipsis-v" />
            </IconButton>
          }
        >
          <Popup>
            <LinkTo to={`/contacts/${id}?modal=${MODALS.editContact}`}>
              <MenuItem value="edit" icon={<Icon icon="uil:edit" />} onClick={() => {}}>
                {t('common.edit')}
              </MenuItem>
            </LinkTo>
          </Popup>
        </Dropdown>
      )
    },
    width: 30,
    disableSortBy: true,
  },
]

export default function Contacts() {
  const [t] = useTranslation()
  const { state } = useUserContext()
  const { i_realm } = state?.realm
  const { data: contacts, isLoading } = useContacts(i_realm)

  const tableProps = useTable(
    {
      columns,
      data: contacts ?? emptyArray,
      initialState: { pageSize: 20, globalFilter: initialContactsGlobalFilter }, // Pass our hoisted table state
      globalFilter: contactsGlobalFilter,
      autoResetPage: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const globalFilter = tableProps.state.globalFilter as ContactsGlobalFilter
  const filteredRows = tableProps.globalFilteredRows.map(({ original }) => original)

  const handleChangeSearch = useCallback(
    (search: string) => {
      tableProps.setGlobalFilter({ ...globalFilter, search })
    },
    [globalFilter, tableProps]
  )

  const handleExportCsv = () => {
    const preparedData = prepareContactsCsvData(filteredRows)
    const csvData = arrayToCSV(preparedData)
    downloadCsv(`devices[${dayjs(new Date()).format('DD-MM-HHmmss')}].csv`, csvData)
  }

  return (
    <Page
      title={t('page.contacts.title')}
      filter={
        <AdvancedFilters
          filteredRows={filteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={tableProps.setGlobalFilter}
        />
      }
    >
      <PageHeader
        title={t('page.contacts.title')}
        subtitle={contacts?.length.toString()}
        search={globalFilter.search}
        onChangeSearch={handleChangeSearch}
        onExportCsv={handleExportCsv}
      />
      <Table isLoading={isLoading} {...tableProps} />
    </Page>
  )
}
