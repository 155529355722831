import React, { FC } from 'react'
import { ModalProps, ModalView } from '../../../components/organisms/Modal'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { emailSentLogsFn } from '../../../api'
import { find } from 'lodash'
import { useUserContext } from '../../../contexts/UserContext'

const ViewEmailDialog: FC<ModalProps> = ({ onClose }) => {

  const [t] = useTranslation()

  const params = useParams<{ id: string }>()
  const email_template_id = +params.id!

  const { state } = useUserContext()
  const { i_realm } = state?.realm

  const { data: selectedSentEmail } = useQuery(['email_sent_logs', i_realm], emailSentLogsFn, {
    select: (data) => find(data, (i) => i.id === email_template_id)
  })

  return (
    <ModalView title={t('page.emails.email')} onClose={onClose}>
      <Content>
        <div>
          <b>{t('page.emails.recipient')}</b>:{selectedSentEmail?.recipient}&nbsp;&nbsp;
          &nbsp;&nbsp;<b>{t('page.emails.email')}</b>: {selectedSentEmail?.email}<br />
        </div>
        <div>
          <b>{t('page.emails.created_at')}</b>:{selectedSentEmail?.created_at}&nbsp;&nbsp;
          &nbsp;&nbsp;<b>{t('page.emails.sent_at')}</b>:{selectedSentEmail?.sent_at}<br />
        </div>
        <h5>{selectedSentEmail?.subject}</h5>
        <iframe title={selectedSentEmail?.subject} srcDoc={selectedSentEmail?.body} style={{ width: '100%' }} />
      </Content>
    </ModalView>
  )

}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
`

export default ViewEmailDialog;