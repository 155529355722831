import React from 'react'
import { Page, PageHeader } from '../../components/organisms/layout'
import { LinkTo } from '../../components/atoms/Pointer'
import { MODALS } from '../../components/organisms/Modal'
import Button from '../../components/molecules/Button'
import { Icon } from '@iconify/react'
import { useTranslation } from 'react-i18next'
import Tabs, { Tab } from '../../components/molecules/Tabs'
import EmailTemplates from './EmailTemplates'
import EmailsSent from './EmailsSent'


const Emails: React.FC = () => {
  const [t] = useTranslation()
  const [currentTab, setCurrentTab] = React.useState('templates')

  return (
    <Page title={t('navBar.emails')}>
      <PageHeader
        title={t('navBar.emails')}
        actions={
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              flexWrap: 'wrap-reverse',
              gap: '8px',
            }}
          >
            <Tabs activeTab={currentTab} onSwitch={setCurrentTab}>
              <Tab label={t('page.emails.templates')} value="templates" />
              <Tab label={t('page.emails.sent')} value="email_sent" />
            </Tabs>

            {
              currentTab === 'templates' && (
                <div style={{ display: 'flex' }}>
                  <LinkTo to={`/emails?modal=${MODALS.addEditEmailTemplate}`}>
                    <Button icon={<Icon icon="uil:plus" />}>{t('page.emails.addTemplate')}</Button>
                  </LinkTo>
                </div>
              )
            }
          </div>
        }
      />
      {
        currentTab === 'templates' && (<EmailTemplates />)
      }
      {
        currentTab === 'email_sent' && (<EmailsSent />)
      }
    </Page>
  )
}


export default Emails